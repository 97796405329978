<template>
  <v-navigation-drawer
      v-model="drawer"
      width="400"
      app
      temporary
      right
      class="wost-drawer"
  >
    <div class="navigation-head">
      <span>{{ titleForm }}</span>
    </div>
    <div class="pl-3 pt-3 pr-3" style="height: 80vh;">
      <template>
        <!--
        <v-row>
            <v-col cols="12">
                <v-autocomplete
                    v-model="value_office"
                    :items="offices"
                    hide-details
                    label="Seleccione Sucursal"
                    outlined
                    :item-text="customText"
                    item-value="id"
                ></v-autocomplete>
            </v-col>
        </v-row>
        -->

        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model="value_category"
                :items="categories"
                label="Seleccione categoría"
                hide-details
                outlined
                :item-text="customText"
                item-value="id"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-autocomplete
                :value="value_stock"
                :items="item_stock"
                hide-details
                outlined
                label="Stock"
                clearable
                @change="set_value_stock"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
    </div>

    <div class="mt-3 pl-3 pt-3 pr-3">
      <template>
        <v-row>
          <v-col cols="6">
            <v-btn block height="40" dark color="red" @click="handleClearFilters">
              <v-icon size="16" class="mr-1">far fa-trash-alt</v-icon>
              Quitar filtros
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn dark color="blue" block height="40" @click="handleSearch">
              <v-icon size="16" class="mr-1">fas fa-search</v-icon>
              Filtrar datos
            </v-btn>
          </v-col>
        </v-row>
      </template>

    </div>

  </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'

import ArrayTools from '../../helpers/ArrayTools'

export default {
  name: 'FiltersPaginated',
  data() {
    return {
      drawer: false,
      titleForm: null,
      filters: {
        name: null,
        company_id: null
      },
      companies: [],
      value_company: null,
      disabled_company: false,
      value_sede: null,
      disabled_sede: false,
      value_office: null,
      value_category: null,
      item_stock: [
        'Con Stock', 'Sin Stock', 'Stock Mínimo'
      ]

    }
  },
  computed: {
    ...mapState('authentication', [
      'select_office',
      'user_companies',
      'user_sedes',
      'user_offices',
      'office_id',
      'company_id'
    ]),
    ...mapState('inventories', [
      'main_storehouse',
      'filtersPaginated',
      'office_filtered',
      'value_stock'
    ]),
    ...mapState('sedes', [
      'sedes'
    ]),
    ...mapState('offices', [
      'offices'
    ]),
    ...mapState('categories', [
      'categories'
    ]),
  },
  methods: {
    ...mapMutations('inventories', [
      'set_value_stock',

    ]),
    ...mapActions('inventories', [
      'clean_data',
      'getInventories',
      'run_set_main_storehouse',
      'run_set_office_id',
      'getList',
      'selectOfficeFiltered',
      'changeFilterPaginated'
    ]),
    showForm(title) {
      this.titleForm = title
      this.drawer = !this.drawer
    },
    closeForm() {
      this.drawer = false
    },
    clearData() {
      this.value_company = null
      this.value_sede = null
      this.value_office = null
      this.value_category = null
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
    },
    customText(item){
      let text = item.name

      return text
    },
    async handleClearFilters() {
      this.clearData()
      this.run_set_main_storehouse(null)
      this.run_set_office_id(null)
      this.selectOfficeFiltered(null);
      this.set_value_stock(null);
      await this.getList({
        paginated:true,
        itemsPerPage:10,
        main_storehouse: this.main_storehouse,
        office_id: this.office_id,});
    },
    async handleSearch() {
      //this.run_set_main_storehouse(null)
      this.run_set_office_id(this.value_office)
      this.selectOfficeFiltered(this.value_office);

      let filters = {
        main_storehouse: this.main_storehouse,
        office_id: this.office_id,
        value_stock: this.value_stock,
        category_id: this.value_category,
        itemsPerPage:10,
        paginated:true
      }
      this.filters=filters
      this.changeFilterPaginated({...this.filters, paginated: true, itemsPerPage: 10})
      await this.getList(filters);
      this.closeForm()
    },
    async getAllInventories() {
      await this.getList({paginated:true,itemsPerPage:10});
    }
  }
}
</script>

<style scoped>

</style>