<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="500"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Conversión de producto</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;" v-if="inventory">
            <template>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                            v-model="inventory.description" 
                            label="Producto seleccionado" 
                            outlined 
                            hide-details
                            readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            ref="quantity_start"
                            v-model="form.quantity_start" 
                            label="Cantidad a convertir" 
                            outlined
                            type="number"
                            :rules="[() => !!form.quantity_start || 'Es necesario ingresar cantidad a convertir']"
                            hide-details="auto"
                            required                           
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-text-field 
                            ref="factor"
                            v-model="form.factor" 
                            label="Equivalencia por unidad" 
                            outlined
                            type="number"
                            :rules="[() => !!form.factor || 'Es necesario ingresar equivalencia por unidad']"
                            hide-details="auto"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            ref="product_id_end"
                            v-model="form.product_id_end"
                            :items="products"
                            label="Convertir a"
                            outlined
                            :item-text="customText"
                            item-value="id"
                            clearable
                            :rules="[
                                () => !!form.product_id_end || 'Es necesario seleccionar un producto',
                                () => form.product_id_end != form.product_id_start || 'Los productos deben ser diferentes' ]"
                            hide-details="auto"
                            required
                        ></v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </div>

        <div class="mt-3 pl-3 pt-3 pr-3">
            <template>
                <v-row>
                    <v-col cols="6">
                        <v-btn block height="40" dark color="red" @click="handleCancel">
                            <v-icon class="mr-1" size="16">far fa-times-circle</v-icon>
                            Cancelar
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn dark color="success" block height="40" @click="handleStore">
                            <v-icon class="mr-1" size="16">far fa-save</v-icon>
                            Guardar
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </div>
    </v-navigation-drawer>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
    name: 'ConvertProduct',
    data () {
        return {
            drawer: false,
            form: {
                inventory_id: null,
                product_id_start: null,
                quantity_start: null,
                product_id_end: null,
                factor: null
            }
        }
    },
    computed: {
        ...mapState('inventories', [
            'inventory',
        ]),
        ...mapState('products', [
            'products'
        ])
    },
    methods: {
        ...mapActions('products', [
            'getProducts'
        ]),
        ...mapActions('inventories', [
            'convert',
        ]),
        async show () {
            await this.clearData();
            this.form.inventory_id = this.inventory.id;
            this.form.product_id_start = this.inventory.product_id;
            this.drawer = !this.drawer;
        },
        clearData () {
            this.form.inventory_id = null;
            this.form.product_id_start = null;
            this.form.quantity_start = null;
            this.form.product_id_end = null;
            this.form.factor = null;
            this.clearErrors();
        },
        customText(item){
            let text = item.name
            
            return text
        },
        existErrors(){
            let hasErrors = false;

            Object.keys(this.form).forEach(f => {                
                if (this.$refs[f]) {
                    if (!this.form[f]) hasErrors = true
                    this.$refs[f].validate(true);
                }                
            });

            return hasErrors;
        },
        clearErrors() {
            Object.keys(this.form).forEach(f => {
                if (this.$refs[f]) this.$refs[f].reset()                
            });
        },
        handleCancel () {
            this.clearData();
            this.drawer = false;
        },
        async handleStore () {
            if (!this.existErrors()) {
                await this.convert(this.form);
                this.clearData();
                this.drawer = false;
            }
        }
    },
    created () {
        this.getProducts();
    }
}
</script>

<style scoped>

</style>