<template>
    <v-navigation-drawer
        v-model="drawer"          
        width="500"
        app
        temporary
        right
        class="wost-drawer"
    >
        <div class="navigation-head">
            <span>Disponibilidad de producto</span>                         
        </div>

        <div class="pl-3 pt-3 pr-3" style="height: 80vh;" v-if="inventory">
            <template>
                <v-row>
                    <v-col>
                        <v-icon size="18" color="red" class="mr-2">
                            fas fa-tags
                        </v-icon>
                        <span>{{ inventory.description | uppercase }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-data-table
                            :headers="headers"
                            :items="stores"
                            :items-per-page="10"
                            class="elevation-1 w-table"
                            height="70vh"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
        </div>

    </v-navigation-drawer>
</template>

<script>
import {mapState} from 'vuex';

export default {
    name: 'StockStores',
    data () {
        return {
            drawer: false,
            headers: [
                { text: 'SUCURSAL', value: 'office_name', sortable: false },
                { text: 'STOCK', value: 'stock', align: 'end', sortable: false },
            ],
        }
    },
    computed: {
        ...mapState('inventories', [
            'inventory',
            'stores'
        ])
    },
    methods: {
        show () {
            this.drawer = ! this.drawer;
        }
    }
}
</script>

<style scoped>

</style>