<template>
    <v-dialog
        v-model="dialog"
        width="500"
        transition="fab-transition"
    >
        <v-card class="pl-0 pr-0">
            <v-card-title>
                <v-icon class="mr-3">fas fa-bars</v-icon>
                <span class="description-selected">
                    Áreas
                </span> 
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pb-0">
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="form.area_id"
                                :items="areas_api"
                                label="Seleccione área"
                                outlined
                                item-text="name"
                                item-value="id"
                                clearable
                                hide-details="auto"
                                :loading="isLoading"
                                :search-input.sync="search_area"
                                hide-no-data
                                hide-selected
                                placeholder="Ingrese el área a buscar"
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="pt-0">
                <v-container class="pt-0 pb-0">
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-btn dark color="red" block @click="handleCancel">
                                Cancelar
                            </v-btn>
                        </v-col>                       
                        <v-col cols="6">
                            <v-btn dark color="green" block @click="handleOk">
                                Guardar Consumo
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>                    
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions} from 'vuex';

import AreasApi from '../../apis/Areas';

export default {
    name: 'AreasDialog',
    data () {
        return {
            dialog: false,
            form: {
                area_id: null,
                main_storehouse: null,
                office_id: null,
                new_stocks: null
            },
            isLoading: false,
            search_area: null,
            areas_api: [],
        }
    },
    watch: {
        async search_area (value) {
            if (value == null || value == undefined || value.length == 0) {
                return;
            }

            if (this.areas_api.length > 0) {
                return;
            }

            if (this.isLoading) return

            this.isLoading = true

            let filters = {
                name: value
            }
            AreasApi.get_all(filters)
                .then( response => {
                    this.areas_api = response.data;
                })
                .catch ( error => {
                    console.log(error);
                })
                .finally( () => (this.isLoading = false));
        },
    },
    methods: {
        ...mapActions('inventories', [
            'storeDomesticConsumption'
        ]),
        show (formData) {
            this.clearData();
            this.setFormData(formData);
            this.dialog = !this.dialog;
        },
        setFormData (data) {
            this.form.main_storehouse = data.main_storehouse;
            this.form.office_id = data.office_id;
            this.form.new_stocks = data.new_stocks;
        },
        clearData () {
            this.form.area_id = null;
            this.form.main_storehouse = null;
            this.form.office_id = null;
            this.form.new_stocks = null;
        },
        closeForm () {
            this.clearData();
            this.dialog = false;
        },
        handleCancel () {
            this.closeForm();
        },
        async handleOk () {
            await this.storeDomesticConsumption(this.form);
            this.clearData();
            this.dialog = false;
        }
    }
}
</script>

<style scoped>
.list-imeis-kardex{
    height: 35vh;
    overflow-y: auto;
}

.v-list-item{
    min-height: 30px !important;
}
</style>